import { Balancer } from 'react-wrap-balancer';

import { Header } from '@/widgets/header';

import { UrlForm } from '@/features/url-form';

import { Badge } from '@/shared/components/ui/badge';
import { PageSeo } from '@/shared/lib/seo';

export const HomePage = () => {
  return (
    <>
      <PageSeo
        title="URL Shortener"
        description="Urlbite is a free and open-source tool to generate short links with analytics making it easy to share and track your links"
      />

      <div className="flex min-h-full flex-col py-4">
        <Header />

        <div className="my-16 flex w-full flex-1">
          <div className="container flex flex-1">
            <div className="flex flex-1 items-center justify-center">
              <div className="grid h-full w-full grid-cols-1 gap-20 lg:grid-cols-2">
                <div className="flex flex-col items-center justify-center gap-4 lg:items-start">
                  <Badge variant="secondary">Beta</Badge>
                  <h1 className="flex flex-col text-center text-6xl lg:text-left">Shorten your urls</h1>

                  <Balancer as="span" className="block text-center text-lg text-muted-foreground lg:text-left">
                    Urlbite is a free and open-source tool to generate short links with analytics making it easy to
                    share and track your urls.
                  </Balancer>
                </div>

                <div className="flex items-center justify-center">
                  <UrlForm />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="container mt-auto">
          <h2 className="mb-4 text-2xl">Examples</h2>

          <BentoGrid>
            <BentoGridItem
              title="Counter"
              description="Demonstrates basic state management with Effector"
              header={<Counter />}
              // icon={item.icon}
            />

            <BentoGridItem
              title="Quote of the day"
              description="Demonstrates data fetching with Effector"
              header={<QuoteCard />}
              className="md:col-span-2"
              // icon={item.icon}
            />
          </BentoGrid>
        </div> */}
      </div>
    </>
  );
};
